import { useEffect, useState } from "react";
import "../css/Login.css";
import { useNavigate } from "react-router-dom";
import apiUrl from "../components/Api";

export default function Login() {
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");

	const navigate = useNavigate();

	function login() {
		fetch(`${apiUrl}/users/login`, {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				username,
				password,
			}),
		})
			.then(async (r) => {
				if (r.status == 201) {
					navigate("/messenger");
				}
				r = await r.json();
				if (r.message) window.addPush(r.message);
			})
			.catch((error) => console.error("Error while login:", error));
	}

	useEffect(() => {
		fetch(`${apiUrl}/chat/getChats`, {
			method: "POST",
			credentials: "include",
		})
			.then((response) => response.json())
			.then((r) => {
				if (r.message) {
					//window.addPush(r.message);
				} else {
					navigate("/messenger");
				}
			})
			.catch((e) => {
				console.log(e);
			});
	}, []);

	return (
		<div className="Login">
			<div className="LoginWindow">
				<img src="./logo.webp" />
				<input
					value={username}
					onChange={(e) => {
						setUsername(e.target.value);
					}}
					placeholder="Логин"
				/>
				<input
					value={password}
					onChange={(e) => {
						setPassword(e.target.value);
					}}
					placeholder="Пароль"
				/>
				<button
					onClick={() => {
						login();
					}}
				>
					Войти
				</button>
			</div>
		</div>
	);
}
