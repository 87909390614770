import { useEffect, useRef, useState } from "react";
import Loader from "./Loader";
import { useNavigate } from "react-router-dom";
import {
	IoBrowsersOutline,
	IoInformationOutline,
	IoSettings,
} from "react-icons/io5";
import { TiInfoLarge } from "react-icons/ti";
import { RiSettings3Fill } from "react-icons/ri";
import ChangePassword from "./ChangePassword";
import apiUrl from "./Api";

function handleFilType(type) {
	switch (type) {
		case "sticker":
			return "Стикер";
		case "audio":
			return "Голосовое сообщение";
		case "photo":
			return "Фото";
		case "document":
			return "Документ";
		default:
			return "Файл";
	}
}
function formatDate(date) {
	const now = new Date();
	const diffInMs = now - date;
	const msInDay = 24 * 60 * 60 * 1000;
	const msInMonth = 30 * msInDay;

	if (diffInMs < msInDay) {
		return `${String(date.getHours()).padStart(2, "0")}:${String(
			date.getMinutes()
		).padStart(2, "0")}`;
	} else if (diffInMs < msInMonth) {
		return `${String(date.getDate()).padStart(2, "0")}.${String(
			date.getMonth() + 1
		).padStart(2, "0")}`;
	} else {
		return `${String(date.getDate()).padStart(2, "0")}.${String(
			date.getMonth() + 1
		).padStart(2, "0")}.${date.getFullYear()}`;
	}
}

function Chats({
	chats,
	setChats,
	activeChat,
	setActiveChat,
	setMessages,
	setIsOverlayOpened,
}) {
	const [isMenuOpened, setIsMenuOpened] = useState(false);
	const searchInput = useRef(null);
	const [searchText, setSearchText] = useState("");

	const [searchedChats, setSearchedChats] = useState([]);

	const navigate = useNavigate();

	useEffect(() => {
		if (searchText === "") return;
		setActiveChat(null);
		navigate("/messenger");

		fetch(`${apiUrl}/chat/searchMessagesAndChat`, {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				text: searchText,
			}),
		})
			.then((response) => response.json())
			.then((r) => {
				if (r.message) {
					window.addPush(r.message);
				}
				return r;
			})
			.then((chats) => setSearchedChats(chats))
			.catch((error) => console.error("Error loading chats:", error));
	}, [searchText]);

	if (chats.length === 0) return <Loader />;
	return (
		<div className={["Chats", activeChat ? "Hidden" : null].join(" ")}>
			<div className="FunctionalBar">
				{isMenuOpened ? (
					<ChangePassword setIsMenuOpened={setIsMenuOpened} />
				) : null}
				<input
					value={searchText}
					onChange={(e) => {
						setSearchText(e.target.value);
					}}
					className="Search"
					placeholder="Поиск"
				/>

				<IoBrowsersOutline
					onClick={() => {
						setIsOverlayOpened((is) => !is);
					}}
					size={24}
				/>
				<TiInfoLarge
					onClick={() => {
						navigate("/info");
					}}
					size={24}
				/>
				<RiSettings3Fill
					onClick={() => {
						setIsMenuOpened((isMenuOpened) => {
							return !isMenuOpened;
						});
					}}
					size={24}
				/>
			</div>

			{(searchedChats.length > 0 && searchText !== ""
				? searchedChats
				: chats
			).map((chat, key) => {
				var unRead =
					searchedChats.length > 0
						? "0"
						: chat.messages
								?.slice(
									chat.messages?.findLastIndex((e) => e.support !== null) + 1
								)
								.filter((e) => e.support == null).length;

				return (
					<div
						onClick={() => {
							if(activeChat === chat.id) return;
							setSearchText("");
							setSearchedChats([]);
							setActiveChat(chat.id);
							navigate(
								`/messenger/${chat.id}/${
									chat.messages[chat.messages.length - 1].id
								}`
							);
							setMessages(
								[...chat.messages].sort(
									(a, b) =>
										new Date(b.timestamp).getTime() -
										new Date(a.timestamp).getTime()
								)
							);
						}}
						className={`Chat ${activeChat === chat.id && "Active"}`}
						key={key}
					>
						<div className="Avatar">{chat.name.slice(0, 1).toUpperCase()}</div>
						<div className="User">
							<p className="Name">{chat.name}</p>
							<p className="Message">
								{chat.messages?.[chat.messages?.length - 1].support
									? chat.messages?.[chat.messages?.length - 1].support
											.username + ": "
									: null}
								{chat.messages?.[chat.messages?.length - 1]?.message ||
									handleFilType(
										chat.messages?.[chat.messages?.length - 1]?.attachments.type
									)}
							</p>
						</div>
						<p className="Date">
							{formatDate(
								new Date(chat.messages?.[chat.messages?.length - 1]?.timestamp)
							)}
						</p>
						{unRead > 0 ? (
							<p className="Unread">{unRead > 9 ? "9+" : unRead}</p>
						) : null}
					</div>
				);
			})}
		</div>
	);
}

export default Chats;
