import { useState } from "react";
import apiUrl from "./Api";

export default function ChangePassword({ setIsMenuOpened }) {
	const [oldPass, setOldPass] = useState("");
	const [newPass, setNewPass] = useState("");
	const [repeatNew, setRepeatNew] = useState("");

	const [promoMinus, setPromoMinus] = useState(0.05);

	function change() {
		if (!oldPass) return window.addPush("Введите старый пароль");
		if (!newPass) return window.addPush("Введите новый пароль");
		if (!repeatNew) return window.addPush("Повторите новый пароль");

		if (newPass !== repeatNew) return window.addPush("Пароли не совпадают");

		fetch(`${apiUrl}/users/changePassword`, {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				previousPassword: oldPass,
				newPassword: newPass,
			}),
		})
			.then((response) => response.json())
			.then((r) => {
				if (r.message) {
					window.addPush(r.message);
				} else {
					window.addPush("Неизвестная ошибка");
				}
			})
			.catch((e) => {
				console.log(e);
			});
	}

	function createPromo() {
		fetch(`${apiUrl}/users/createPromo`, {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				promoMinus: promoMinus,
			}),
		})
			.then((r) => r.json())
			.then((r) => {
				if (r.message) {
					try {
						if (r.promo) {
							navigator.clipboard.writeText(r.promo);
							window.addPush("Промокод скопирован в буфер обмена!");
						}
					} catch {}
				} else {
					window.addPush("Неизвестная ошибка");
				}
			})
			.catch((e) => {
				console.log(e);
			});
	}

	return (
		<div className="MicroMenu">
			<input
				value={oldPass}
				onChange={(e) => {
					setOldPass(e.target.value);
				}}
				type="password"
				placeholder="Старый пароль"
			/>
			<input
				value={newPass}
				onChange={(e) => {
					setNewPass(e.target.value);
				}}
				type="password"
				placeholder="Новый пароль"
			/>
			<input
				value={repeatNew}
				onChange={(e) => {
					setRepeatNew(e.target.value);
				}}
				type="password"
				placeholder="Повторите новый пароль"
			/>
			<button
				onClick={() => {
					change();
				}}
			>
				Изменить
			</button>
			<br />
			<br />
			<br />
			<div style={{ display: "flex", justifyContent: "space-between" }}>
				<p>Процент промокода</p>
				<p>{promoMinus}</p>
			</div>
			<input
				type="range"
				onChange={(e) => setPromoMinus(e.target.value)}
				value={promoMinus}
				defaultValue={0.5}
				min={0.05}
				max={0.5}
				step={0.05}
			/>
			<button
				onClick={() => {
					createPromo();
				}}
			>
				Создать
			</button>
			<br />
			<br />
			<br />
			<button
				onClick={() => {
					setIsMenuOpened(false);
				}}
			>
				Закрыть
			</button>
		</div>
	);
}
